import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Avatar,
} from "@mui/material";
import { spacing, display } from "@mui/system";
import agent from "../../utils/axios";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PayeeBills from "./PayeeBills";
import { capitalizeFirstLetter } from "../../utils/text";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(display);

type PayeeDataSchema = {
  [key: string]: any;
  id: string;
  name: {
    first: string;
    middle: string;
    last: string;
  };
  mailingAddress: {
    address1: string;
    address2: string;
    address3: string;
    city: string;
    state: string;
    postalCode: string;
  };
  phone: {
    home: string;
    cell: string;
  };
  tin: string;
  last4: string;
  email: string;
  type: string;
  payable: boolean;
  bills: any;
};

function PayeeDetails() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const Status = queryParams.get("Status");
  const { id } = useParams();

  const [payeeData, setPayeeData] = useState<PayeeDataSchema | null>(null);
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    async function getPayee() {
      try {
        const response = await agent.Payees.getPayee(id ?? "");
        setPayeeData(response);
      } catch (error) {
        console.error("Error fetching payee:", error);
      }
    }

    getPayee();
  }, [id]);

  if (payeeData === null) {
    // Data is still being fetched, show a loading indicator or return null
    return <div>Loading...</div>;
  }

  const filteredBills = Status
    ? payeeData.bills.filter(
        (item: { paymentService: { paymentStatus: string | null } }) =>
          item.paymentService.paymentStatus === Status
      )
    : payeeData.bills;

  return (
    <React.Fragment>
      <Helmet title={`Payee Details - ${id}`} />

      <Typography variant="h3" gutterBottom display="inline">
        Payee
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/payees">
          Payees
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={12}>
          <Shadow>
            <Card px={6} pt={6}>
              <CardContent>
                <Grid container spacing={6}>
                  <Typography variant="h4" gutterBottom>
                    {payeeData.name.first} {payeeData.name.middle}{" "}
                    {payeeData.name.last}
                    <Chip
                      label={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography variant="body2">
                            {" "}
                            {payeeData.payable ? "Payable" : "Not Payable"}
                          </Typography>
                          <Avatar
                            style={{ marginLeft: 4, width: 16, height: 16 }}
                          >
                            <InfoOutlinedIcon fontSize="small" />
                          </Avatar>
                        </div>
                      }
                      color={payeeData.payable ? "success" : "warning"}
                    />
                  </Typography>
                </Grid>
              </CardContent>

              <div>
                <Accordion>
                  <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Grid item xs={3}>
                      <Typography variant="body1">GENERAL</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography>
                        <strong>Payee ID</strong> {id}
                      </Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Nulla facilisi. Phasellus sollicitudin nulla et quam
                      mattis feugiat. Aliquam eget maximus est, id dignissim
                      quam.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <Grid item xs={3}>
                      <Typography variant="body1">CONTACT</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography>
                        <strong>Type</strong>{" "}
                        {capitalizeFirstLetter(payeeData.type)}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography>
                        <strong>Name</strong> {payeeData.name.first}{" "}
                        {payeeData.name?.middle} {payeeData.name.last}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography>
                        {" "}
                        <strong>Contact email</strong> {payeeData.email}
                      </Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={-6}>
                      <Grid item xs={3}>
                        <Typography variant="body1"></Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography paragraph>
                          <strong>Phone number</strong> <br />
                          {payeeData.phone.home && (
                            <>
                              Home {payeeData.phone.home}
                              <br />
                            </>
                          )}
                          {payeeData.phone.cell && (
                            <>Cell {payeeData.phone.cell}</>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <div>
                          <Typography>
                            <strong>Address</strong>{" "}
                            {payeeData.mailingAddress?.address1}{" "}
                            {payeeData.mailingAddress?.address2}{" "}
                            {payeeData.mailingAddress?.address3}{" "}
                            {payeeData.mailingAddress.city},{" "}
                            {payeeData.mailingAddress.state}{" "}
                            {payeeData.mailingAddress.postalCode}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                  >
                    <Grid container spacing={-6}>
                      <Grid item xs={3}>
                        <Typography variant="body1">
                          Current Payment Method
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="subtitle1">
                          {payeeData.paymentMethod}
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                </Accordion>
              </div>
            </Card>
          </Shadow>
          <PayeeBills billData={filteredBills} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PayeeDetails;
