import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  TextField,
} from "@mui/material";
import { RemoveRedEye as RemoveRedEyeIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import agent from "../../utils/axios";
import LoadingSpinner from "../Loading/LoadingSpinner";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);
const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;
type RowType = {
  [key: string]: any;
  id: string;
  name: {
    first: string;
    middle: string;
    last: string;
  };
  mailingAddress: {
    address1: string;
    address2: string;
    address3: string;
    city: string;
    state: string;
    postalCode: string;
  };
  phone: {
    home: string;
    cell: string;
  };
  tin: string;
  last4: string;
  email: string;
  status: number;
  method: string;
};

type HeadCell = {
  id: string;
  alignment: "left" | "center" | "right" | "justify" | "inherit" | undefined;
  label: string;
  disablePadding?: boolean;
};
const headCells: Array<HeadCell> = [
  { id: "id", alignment: "right", label: "Payee Id" },
  { id: "name", alignment: "left", label: "Name" },
  { id: "address", alignment: "left", label: "Address" },
  { id: "cell phone", alignment: "left", label: "Cell Phone" },
  { id: "home phone", alignment: "left", label: "Home Phone" },
  { id: "ssn", alignment: "left", label: "Last4" },
  { id: "email", alignment: "left", label: "Email" },
  { id: "actions", alignment: "right", label: "Actions" },
];

type EnhancedTableHeadProps = {
  numSelected: number;
  order: "desc" | "asc";
  orderBy: string;
  rowCount: number;
  onSelectAllClick: (e: any) => void;
  onRequestSort: (e: any, property: string) => void;
};
const EnhancedTableHead: React.FC<EnhancedTableHeadProps> = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: HeadCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

type EnhancedTableToolbarProps = { numSelected: number };

function EnhancedTable() {
  const [loading, setLoading] = React.useState(true);
  const [order, setOrder] = useState<"desc" | "asc">("asc");
  const [orderBy, setOrderBy] = useState("payeeId");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [data, setData] = useState<Array<RowType>>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  async function getPayees() {
    setLoading(true);
    try {
      const response = await agent.Payees.getAllPayees(
        page,
        rowsPerPage,
        orderBy,
        order,
        searchTerm
      );
      setData(response.data); // Adjust based on actual response structure
      setTotalCount(response.count); // Assume totalCount comes from the response
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    // Fetch payees data when the component mounts
    getPayees();
  }, [page, rowsPerPage, searchTerm]);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0); // Reset to the first page on sort change
    getPayees(); // Fetch data with new sorting
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    getPayees(); // Fetch new page data
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    getPayees(); // Fetch data for updated rows per page
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <>
      <Grid item>
        <Paper p={2} mb={4}>
          <TextField
            label="Search Payees"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search by Payee ID, Name, Address and email"
          />
        </Paper>
      </Grid>

      {loading && <LoadingSpinner />}
      <Paper p={3}>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                {headCells.map((headCell, index) => (
                  <TableCell
                    key={index}
                    align={headCell.alignment}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => handleRequestSort(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="right">{row.id}</TableCell>
                  <TableCell align="left">{`${row.name.first} ${
                    row.name.last ?? ""
                  }`}</TableCell>

                  <TableCell align="left">
                    {row.mailingAddress.address1}
                    {row.mailingAddress?.address2 &&
                      ` ${row.mailingAddress.address2}`}
                    {row.mailingAddress?.address3 &&
                      ` ${row.mailingAddress.address3}`}
                    {row.mailingAddress?.city && ` ${row.mailingAddress.city}`}
                    {row.mailingAddress?.state &&
                      ` ${row.mailingAddress.state},`}
                    {row.mailingAddress?.postalCode &&
                      ` ${row.mailingAddress.postalCode}`}
                  </TableCell>

                  <TableCell align="left">{row.phone.cell}</TableCell>
                  <TableCell align="left">{row.phone.home}</TableCell>
                  <TableCell align="left">{row.last4}</TableCell>
                  <TableCell align="left">{row.email}</TableCell>

                  <TableCell padding="none" align="right">
                    <Box mr={2}>
                      <IconButton
                        aria-label="details"
                        component={NavLink}
                        to={`/payees/detail/${row.id}`}
                        size="large"
                      >
                        <RemoveRedEyeIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 250]}
          component="div"
          count={totalCount} // Use totalCount here
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}

function PayeesList() {
  return (
    <React.Fragment>
      <Helmet title="Payees" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Payees
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Payees</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PayeesList;
