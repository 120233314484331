import React, { useEffect, useState } from "react";
import {
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Chip,
  Badge,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import agent from "../../utils/axios";
import LoadingSpinner from "../Loading/LoadingSpinner";

/* interface AuthUrlResponse {
  url: string;
}
 */
// Separate the styled components
const Paper = styled(MuiPaper)(spacing);

type HeadCell = {
  id: string;
  alignment: "left" | "center" | "right" | "justify" | "inherit" | undefined;
  label: string;
  disablePadding?: boolean;
};

const headCells: Array<HeadCell> = [
  { id: "invoiceId", alignment: "left", label: "Invoice ID" },
  { id: "invoiceDate", alignment: "left", label: "Invoice Date" },
  { id: "totalCount", alignment: "left", label: "Total Count" },
  { id: "totalAchCount", alignment: "left", label: "Total Ach" },
  { id: "totalCheckCount", alignment: "left", label: "Total Check" },
  /*   { id: "actions", alignment: "left", label: "Actions" }, */
];

type RowType = {
  [key: string]: any;
  invoiceId: string;
  totalCount: number;
  totalAchCount: number;
  totalCheckCount: number;
  achPaidCount: number;
  checkPaidCount: number;
};

const InvoiceCounts = () => {
  const [loading, setLoading] = React.useState(true);
  const [order, setOrder] = useState<"desc" | "asc">("asc");
  const [orderBy, setOrderBy] = useState("payeeId");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [data, setData] = useState<Array<RowType>>([]);
  const [totalCount, setTotalCount] = useState(0);

  async function getInvoices() {
    setLoading(true);
    try {
      const response = await agent.Invoices.getInvoiceCounts();
      setData(response);
      setTotalCount(response.length); // Assume totalCount comes from the response
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getInvoices();
  }, [page, rowsPerPage]);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0);
  };

  /*  const handleProcessClick = async (invoiceId: string) => {
    setLoading(true);
    try {
      const { url }: AuthUrlResponse = await agent.quickbooks.getAuthUrl();
      if (url) {
        const urlParams = new URLSearchParams(new URL(url).search);
        const redirect_uri = urlParams.get("redirect_uri") ?? "";
        const popupWidth = 600;
        const popupHeight = 700;
        const left = window.screen.width / 2 - popupWidth / 2;
        const top = window.screen.height / 2 - popupHeight / 2;

        const authWindow = window.open(
          url,
          "QuickBooks Login",
          `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
        );

        // Check if the popup opened successfully
        if (!authWindow) {
          setLoading(false);
          return;
        }

        const authCheckInterval = setInterval(() => {
          try {
            if (authWindow.location.href.includes("code=")) {
              const params = new URLSearchParams(authWindow.location.search);
              const code = params.get("code");
              if (code) {
                clearInterval(authCheckInterval);
                authWindow.close();
                handleAuthCode(code, invoiceId, redirect_uri);
              }
            }
          } catch (error) {
            // Ignore cross-origin errors until the popup is redirected to your domain with the code.
          }

          if (authWindow.closed) {
            clearInterval(authCheckInterval);
          }
        }, 200);
      }
    } catch (error) {
      setLoading(false);
    }
  }; */

  /*   const handleAuthCode = async (
    code: string | null,
    invoiceId: string,
    redirect_uri: string
  ) => {
    if (code) {
      try {
        const authRequest: Authenticate = { code };

        await agent.quickbooks.authenticateQuickbooks(
          authRequest,
          redirect_uri
        );
        await agent.Bills.reprocessFailedBills(invoiceId);
      } catch (error) {
        console.error("Error processing QuickBooks authentication:", error);
      } finally {
        setLoading(false);
      }
    }
  }; */

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    getInvoices(); // Fetch data for updated rows per page
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Paper>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                {headCells.map((headCell, index) => (
                  <TableCell
                    key={index}
                    align={headCell.alignment}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => handleRequestSort(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.invoiceId}</TableCell>
                  <TableCell>
                    {" "}
                    {new Intl.DateTimeFormat("en-US", {
                      dateStyle: "medium",
                    }).format(new Date(row.invoiceDate))}
                  </TableCell>
                  <TableCell>
                    <Badge
                      badgeContent={
                        row.totalCount - (row.achPaidCount + row.checkPaidCount)
                      }
                      color="primary"
                    >
                      <Chip
                        label={row.totalCount}
                        color={
                          row.achPaidCount + row.checkPaidCount ===
                          row.totalCount
                            ? "primary"
                            : "error"
                        }
                        variant="outlined"
                      />
                    </Badge>
                  </TableCell>
                  <TableCell>
                    <Badge
                      badgeContent={row.totalAchCount - row.achPaidCount}
                      color="primary"
                    >
                      <Chip
                        label={row.totalAchCount}
                        color={
                          row.achPaidCount === row.totalAchCount
                            ? "primary"
                            : "error"
                        }
                        variant="outlined"
                      />{" "}
                    </Badge>
                  </TableCell>
                  <TableCell>
                    <Badge
                      badgeContent={row.totalCheckCount - row.checkPaidCount}
                      color="primary"
                    >
                      <Chip
                        label={row.totalCheckCount}
                        color={
                          row.checkPaidCount === row.totalCheckCount
                            ? "primary"
                            : "error"
                        }
                        variant="outlined"
                      />
                    </Badge>
                  </TableCell>

                  {/*  <TableCell padding="none" align="left">
                    {row.achPaidCount + row.checkPaidCount ===
                    row.totalCount ? (
                      <Box mr={2}>
                        <Button size="large" disabled>
                          Completed
                        </Button>
                      </Box>
                    ) : (
                      <Box mr={2}>
                        <Button
                          size="large"
                          onClick={() => handleProcessClick(row.invoiceId)}
                        >
                          Continue Processing
                        </Button>
                      </Box>
                    )}
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 250]}
          component="div"
          count={totalCount} // Use totalCount here
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default InvoiceCounts;
