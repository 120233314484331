import React, { useCallback, useEffect, useState } from "react";
import {
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  IconButton,
  Box,
  TextField,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { Link, NavLink } from "react-router-dom";
import { RemoveRedEye as RemoveRedEyeIcon } from "@mui/icons-material";
import agent from "../../utils/axios";
import LoadingSpinner from "../Loading/LoadingSpinner";

const Paper = styled(MuiPaper)(spacing);

type HeadCell = {
  id: string;
  alignment: "left" | "center" | "right" | "justify" | "inherit" | undefined;
  label: string;
  disablePadding?: boolean;
};

const headCells: Array<HeadCell> = [
  { id: "payeeId", alignment: "left", label: "Payee ID" },
  { id: "payeeName", alignment: "left", label: "Payee Name" },
  { id: "invoiceId", alignment: "left", label: "Invoice Id" },
  { id: "invoiceDate", alignment: "left", label: "Invoice Date" },
  /*   { id: "billRefCode", alignment: "left", label: "Bill Ref Code" }, */
  { id: "billStatus", alignment: "left", label: "Bill Status" },
  { id: "billAmount", alignment: "left", label: "Amount" },
  { id: "paymentMethod", alignment: "left", label: "Payment Method" },
  { id: "", alignment: "right", label: "Actions" },
];

type RowType = {
  [key: string]: any;
  payeeId: string;
  payeeName: string;
  invoiceDate: string;
  invoiceId: string;
  billRefCode: string;
  paymentRefCode: string;
  billStatus: string;
  billAmount: number;
  paymentStatus: string;
  paymentMethod: string;
};

const PendingBills = () => {
  const [loading, setLoading] = React.useState(true);
  const [order, setOrder] = useState<"desc" | "asc">("asc");
  const [orderBy, setOrderBy] = useState("payeeId");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [data, setData] = useState<Array<RowType>>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const getPayees = useCallback(async () => {
    setLoading(true);
    try {
      const response = await agent.Bills.getAllFailedBills(
        page,
        rowsPerPage,
        orderBy,
        order,
        searchTerm
      );
      setData(response.data); // Adjust based on actual response structure
      console.log(response.data);
      setTotalCount(response.count); // Assume totalCount comes from the response
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage, orderBy, order, searchTerm]);

  useEffect(() => {
    getPayees();
  }, [page, rowsPerPage, searchTerm, getPayees]);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0); // Reset to the first page on sort change
    getPayees(); // Fetch data with new sorting
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    getPayees(); // Fetch new page data
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    getPayees(); // Fetch data for updated rows per page
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  //if (loading) return <LoadingSpinner />;
  return (
    <>
      <Paper p={2} mb={4}>
        <TextField
          label="Search Bills"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search by Payee ID, Name, Bill Ref Code..."
        />
      </Paper>
      {loading && <LoadingSpinner />}
      <Paper>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                {headCells.map((headCell, index) => (
                  <TableCell
                    key={index}
                    align={headCell.alignment}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => handleRequestSort(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Link
                      to={`/payees/detail/${row.payeeId}`}
                      style={{ color: "black" }}
                    >
                      {row.payeeId}
                    </Link>
                  </TableCell>
                  <TableCell>{row.payeeName}</TableCell>
                  <TableCell>{row.invoiceId}</TableCell>
                  <TableCell>
                    {new Intl.DateTimeFormat("en-US", {
                      dateStyle: "medium",
                    }).format(new Date(row.invoiceDate))}
                  </TableCell>
                  <TableCell>{row.billStatus}</TableCell>
                  <TableCell>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(row.billAmount)}
                  </TableCell>
                  <TableCell>{row.paymentMethod}</TableCell>
                  <TableCell padding="none" align="right">
                    <Box mr={2}>
                      <IconButton
                        aria-label="details"
                        component={NavLink}
                        to={`/bills/detail/${row.billRefCode}`}
                        size="large"
                        disabled={!row?.billRefCode}
                      >
                        <RemoveRedEyeIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 250]}
          component="div"
          count={totalCount} // Use totalCount here
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default PendingBills;
