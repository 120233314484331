import styled from "@emotion/styled";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Grid,
  Link,
} from "@mui/material";
import { Box, spacing } from "@mui/system";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import InvoiceCounts from "./invoiceCounts";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Invoices() {
  return (
    <>
      <Helmet title="Bills" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Invoices
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Invoices</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", typography: "body1", marginTop: 10 }}>
        <InvoiceCounts />
      </Box>
    </>
  );
}

export default Invoices;
