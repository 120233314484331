import axios, { AxiosError, AxiosResponse } from "axios";
import { isValidToken, setSession } from "./jwt";
import { msalInstance } from "../App";
import { Payment1099 } from "../types/payment1099";
import { pdfavailable } from "../types/pdfavailable";
import { Authenticate } from "../types/Authenticate";

const sleep = () => new Promise((resolve) => setTimeout(resolve, 250));

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const responseBody = (response: AxiosResponse) => response.data;

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("accessToken");

  if (!token) {
    setSession(null);
    msalInstance.logoutRedirect({
      postLogoutRedirectUri: process.env.REACT_APP_CLIENT_URL,
    });
  }

  if (token && config.headers) {
    const isValid = isValidToken(token);

    if (isValid) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      setSession(null);
      msalInstance.logoutRedirect({
        postLogoutRedirectUri: process.env.REACT_APP_CLIENT_URL,
      });
    }
  }
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    if (process.env.NODE_ENV === "development") await sleep();
    return response;
  },
  (error: AxiosError) => {
    if (error.response) {
      const { data, status } = error.response as AxiosResponse;
      switch (status) {
        case 400:
          if (data.errors) {
            const modelStateErrors: string[] = [];
            for (const key in data.errors) {
              if (data.errors[key]) {
                modelStateErrors.push(data.errors[key]);
              }
            }
            throw modelStateErrors.flat();
          }
          console.error(data.message);
          break;
        case 401:
          console.error(data.message);
          break;
        case 403:
          console.error("You are not allowed to do that!");
          break;
        case 500:
          console.error("Server error");
          break;
        default:
          console.error("Network error occurred");
          break;
      }
      return Promise.reject(error.response);
    }

    console.error("Network error: Unable to reach the server");
    return Promise.reject(
      new Error("Network error: Unable to reach the server")
    );
  }
);

const requests = {
  get: (url: string) => axios.get(url).then(responseBody),
  getFile: (url: string) =>
    axios.get(url, { responseType: "arraybuffer" }).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  delete: (url: string) => axios.delete(url).then(responseBody),
  postFile: (url: string, body: {}) =>
    axios.post(url, body, { responseType: "blob" }).then(responseBody),
  postForm: (url: string, data: FormData) =>
    axios
      .post(url, data, {
        headers: { "Content-type": "multipart/form-data" },
      })
      .then(responseBody),
  putForm: (url: string, data: FormData) =>
    axios
      .put(url, data, {
        headers: { "Content-type": "multipart/form-data" },
      })
      .then(responseBody),
};

const Payees = {
  getAllPayees: (
    page: number,
    rowsPerPage: number,
    sortColumn: string,
    sortOrder: string,
    searchTerm?: string
  ) =>
    requests.get(
      `/Payees?pageIndex=${page}&pageSize=${rowsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&searchTerm=${searchTerm}`
    ),
  getPayee: (id: string) => requests.get(`/payees/${id}`),
};

const Bills = {
  getAllBills: (
    page: number,
    rowsPerPage: number,
    sortColumn: string,
    sortOrder: string,
    searchTerm?: string
  ) =>
    requests.get(
      `/Bills?pageIndex=${page}&pageSize=${rowsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&searchTerm=${searchTerm}`
    ),
  getAllFailedBills: (
    page: number,
    rowsPerPage: number,
    sortColumn: string,
    sortOrder: string,
    searchTerm?: string
  ) =>
    requests.get(
      `/Bills/GetFailedBills?pageIndex=${page}&pageSize=${rowsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&searchTerm=${searchTerm}`
    ),
  getBillByRefCode: (billRefCode: string) =>
    requests.get(`/Bills/GetByRefcode/${billRefCode}`),
  convertToCheck: (billRefCode: string) =>
    requests.post(`/Bills/ConvertToCheck/${billRefCode}`, {}),
  reprocessFailedBills: (invoiceId: string) =>
    requests.post(`/Bills/ReprocessFailedBills?InvoiceId=${invoiceId}`, {}),
};

const Payments = {
  getAllPaymentsByYear: (
    Year: string,
    page?: number,
    rowsPerPage?: number,
    sortColumn?: string,
    sortOrder?: string,
    searchTerm?: string
  ) => {
    const params = new URLSearchParams();

    params.append("year", Year);
    if (page !== undefined) params.append("pageIndex", page.toString());
    if (rowsPerPage !== undefined)
      params.append("pageSize", rowsPerPage.toString());
    if (sortColumn) params.append("sortColumn", sortColumn);
    if (sortOrder) params.append("sortOrder", sortOrder);
    if (searchTerm) params.append("searchTerm", searchTerm);

    return requests.get(`/Tax/1099?${params.toString()}`);
  },
  getTaxYear: () => requests.get(`/Tax/taxYears`),
  download1099PDF: (data: Payment1099) =>
    requests.postFile(`/Tax/CreatePDF1099`, data),
};

const Options = {
  getTaxPdfOptionByYear: (year: string) =>
    requests.get(`/Options/GetTaxPdfOptionByYear/${year}`),
  updateTaxPdfOption: (data: pdfavailable) =>
    requests.post(`/Options/UpdateTaxYearDisplay`, data),
};

const Invoices = {
  getInvoiceCounts: () => requests.get(`/Invoice/GetInvoiceCounts`),
};

const quickbooks = {
  getAuthUrl: () => requests.get("/QuickBooks/GetQuickBooksAuthUrl"),
  authenticateQuickbooks: (code: Authenticate, redirect_uri: string) =>
    requests.post(`QuickBooks/Authenticate?redirectUri=${redirect_uri}`, code),
};

const agent = {
  Payees,
  Bills,
  Payments,
  Options,
  Invoices,
  quickbooks,
};

export default agent;
