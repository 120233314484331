import { SidebarItemsType } from "../../types/sidebar";

import { CreditCard, Grid, DollarSign, Users, FileText } from "react-feather";

const pagesSection = [
  {
    href: "/",
    icon: Grid,
    title: "Dashboard",
  },
  /*   {
    href: "/invoices",
    icon: FileText,
    title: "Invoices",
  }, */
  {
    href: "/bills",
    icon: DollarSign,
    title: "Bills",
  },
  {
    href: "/payees",
    icon: Users,
    title: "Payees",
  },
  {
    href: "/payments",
    icon: CreditCard,
    title: "Payments",
  },
] as SidebarItemsType[];

/* const settingSection = [
  {
    href: "/applications",
    icon: Grid,
    title: "Applications",
  },
  {
    href: "/clients",
    icon: PieChart,
    title: "Clients",
  },
  {
    href: "/programs",
    icon: List,
    title: "Programs",
  },
] as SidebarItemsType[]; */

const navItems = [
  {
    title: "Pages",
    pages: pagesSection,
  },
  /* {
    title: "Settings",
    pages: settingSection,
  }, */
];

export default navItems;
