import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

import Page404 from "./components/error/Page404";
import BillDetails from "./features/bills/BillDetails";
import BillList from "./features/bills/BillList";
import Payees from "./features/payees";
import Payments from "./features/payments";
import PayeeDetails from "./features/payees/PayeeDetails";
import Invoices from "./features/invoices/invoices";

// Dashboard components
const Dashboard = async(() => import("./features/dashboard"));

const routes = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
    ],
  },
  {
    path: "invoices",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Invoices />,
      },
    ],
  },
  {
    path: "bills",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <BillList />,
      },
      {
        path: "detail/:billRefCode",
        element: <BillDetails />,
      },
    ],
  },

  {
    path: "payees",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Payees />,
      },
      {
        path: "detail/:id",
        element: <PayeeDetails />,
      },
    ],
  },
  {
    path: "payments",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Payments />,
      },
    ],
  },
  /* {
    path: "applications",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Applications />,
      },
    ],
  },
  {
    path: "clients",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ClientList />,
      },
    ],
  },
  {
    path: "programs",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProgramList />,
      },
    ],
  }, */
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
